 <template>
	<section class="section-wrapper" :class="value.dark_section ? 'dark-section': ''">
        <base-container>
		<base-heading :heading="`${prefix}.title`" :subheading="`${prefix}.subtitle`" v-animate="`slideRight`"/>
        </base-container>
		<grid-template :cols="value.cols ? value.cols : '4'" class="realization-categories">
			<base-card v-for="(item, id) in value.card_schema" :key="item.title" :src="`${prefix}.card_schema.${id}.background`" :text="`${prefix}.card_schema.${id}`" :link="item.link_alias" :title="item.link_title" v-animate="`opacity${id}`"/>
			<template #buttons>
				<cms-multi-section :value="`${prefix}.section_content`"/>
			</template>
		</grid-template>
	</section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import BaseCard from '../../components/molecules/cards/BaseCard/BaseCard.vue'
import BaseHeading from '../../components/molecules/headings/BaseHeading/BaseHeading.vue'
import GridTemplate from '../../templates/GridTemplate.vue'
export default {
	components: {
		GridTemplate,
		BaseCard,
		BaseHeading,
		CmsMultiSection
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    ::v-deep .grid-wrapper {
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(sm) {
            display: grid;
        }
    }
	padding: 4rem 0;
    background-color: $gray-300;

	@include media-breakpoint-up(xl) {
		padding: 8rem 0;
        background-color: $white;
        overflow: hidden;
	}

	&.dark-section {
		background-color: $gray-900;
	}

}
</style>
