<template>
	<base-link :to="link" class="card" :class="{'plain-card': disableHover}" :title="title">
		<cms-image
				class="card-img"
				lazy
				:value="src"
				:base="{width: 320, height: 179}"
				:xl="{width: 450, height: 252}"
			/>
		<cms-text
			class="card-title"
			:value="`${text}.card_title`"
			:props="{size:'md', weight:'medium', color: 'white'}"
		/>
	</base-link>
</template>
<script>
export default {
	props: {
		text: String,
		src: String,
		link: String,
		title: String,
		disableHover: {
			type: Boolean,
			default: false
		}
	}
}
</script>
<style lang="scss" scoped>
.card {
	width: 100%;
	height: 100%;
	max-height: 190px;
    overflow: hidden;
	position: relative;
	::v-deep .base-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	::v-deep &-title {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 2;
		white-space: nowrap;
		font-weight: 300;
		@include media-breakpoint-up(md) {
			white-space: normal;
			text-align: center;
		}
		@include media-breakpoint-up(lg) {
			white-space: nowrap;
		}
		@include media-breakpoint-up(xl) {
			font-size: 31px;
		}
	}
	@include media-breakpoint-up(xl) {
		&:hover {
			&::after {
				height: 100%;
			}
		}
	}
	&::after, &::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
	&::before {
		height: 100%;
		background: rgba($black, .5);
	}
	&::after {
		background: rgba($secondary, .83);
		height: 6px;
		transition: .3s ease-in;
		@include media-breakpoint-up(xl) {
			height: 9px;
		}
	}
	&:last-of-type {
		grid-column: span 2;
	}
}
</style>
